<template>
  <base-card>
    <v-card-text>
      <div>
        <v-row class="align-end mb-6">
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            offset-lg="2"
          >
            <v-text-field
              v-model="city"
              hide-details
              clearable
              :label="$t('address.city')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="street"
              hide-details
              clearable
              :label="$t('address.streetName')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="streetNumber"
              hide-details
              clearable
              :label="$t('address.streetNumber')"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex justify-center justify-md-start"
          >
            <v-btn
              class="primary px-4 mr-4"
              @click="loadSearchedAddress()"
            >
              {{ $t("common.search") }}
            </v-btn>

            <v-btn
              class="error px-4"
              @click="resetForm">
              {{ $t("common.reset") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-alert
          border="left"
          outlined
          type="error"
          elevation="2"
          class="mb-6 mt-2 text-left"
          dismissible
          text
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </v-alert>
      </div>

      <v-data-table
        :headers="headers"
        :items="tableData"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4 mainText--text"
      />
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'

  import TagGroup from '../components/TagGroup'

  export default {
    metaInfo: {
      title: 'Searched Address',
    },

    components: {
      TagGroup,
    },

    data () {
      return {
        tableData: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        headers: [],
        city: '',
        street: '',
        streetNumber: '',
        errorMessage: '',
      }
    },

    computed: {
      ...mapGetters({
        isAdminUser: 'isAdminUser',
      }),
    },

    watch: {
      currentPage(val) {
        this.loadSearchedAddress(val)
      }
    },

    mounted () {
      if (this.isAdminUser) {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('price.customerName'),
          value: 'userName',
        }, {
          text: this.$t('partner.companyName'),
          value: 'partnerName',
        }, {
          text: this.$t('address.streetName'),
          value: 'street',
        }, {
          text: this.$t('address.streetNumber'),
          value: 'streetNumber',
        }, {
          text: this.$t('address.letter'),
          value: 'streetLetter',
        }, {
          text: this.$t('address.city'),
          value: 'locality',
        }, {
          text: this.$t('address.postalCode'),
          value: 'postalCode',
        }]
      } else {
        this.headers = [{
          text: this.$t('common.number'),
          value: 'index',
        }, {
          text: this.$t('common.createdAt'),
          value: 'createdAt',
        }, {
          text: this.$t('price.customerName'),
          value: 'userName',
        }, {
          text: this.$t('address.streetName'),
          value: 'street',
        }, {
          text: this.$t('address.streetNumber'),
          value: 'streetNumber',
        }, {
          text: this.$t('address.letter'),
          value: 'streetLetter',
        }, {
          text: this.$t('address.city'),
          value: 'locality',
        }, {
          text: this.$t('address.postalCode'),
          value: 'postalCode',
        }]
      }
    },

    methods: {
      ...mapActions({
        fetchSearchedAddress: 'fetchSearchedAddress',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async loadSearchedAddress(currentPage = 1) {
        this.currentPage = currentPage
        this.errorMessage = ''

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const payload = {
            locality: this.city,
            street: this.street,
            streetNumber: this.streetNumber,
            offset: (currentPage - 1) * this.limit,
            limit: this.limit,
          }

          const response = await this.fetchSearchedAddress(payload)

          this.totalPages = Math.ceil(response.count / this.limit)
          this.tableData = response.rows.map((item, index) => {
            return {
              ...item,
              createdAt: util.formatDateTime(item.createdAt),
              index: index + (this.currentPage - 1) * this.limit + 1,
              userName: `${item.user.firstName} ${item.user.lastName}`,
              partnerName: item.user.partner?.companyName,
            }
          })
        } catch (error) {
          this.totalPages = 1
          this.tableData = []
          this.currentPage = 1
          this.errorMessage = util.getErrorResponse(error)
        }
        this.setLoading(false)
      },

      resetForm() {
        this.errorMessage = ''
        this.city = ''
        this.street = ''
        this.streetNumber = ''
        this.tableData = []
        this.currentPage = 1
        this.totalPages = 1
      },
    },
  }
</script>
