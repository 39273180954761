<template>
  <div class="tag-group">
    <template v-if="moreItemCount">
      <v-chip
        color="error"
        v-for="index in maxShowItems - 1"
        :key="index"
        class="mr-1 mb-1 rounded-0 text-caption"
      >
        {{ tags[index - 1] }}
      </v-chip>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            color="primary"
            class="text-caption px-3 py-1 mb-1 more-tag-button"
            v-bind="attrs"
            v-on="on"
          >
            + {{ moreItemCount }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, index) in additionalTags"
            :key="index"
            link
          >
            <v-list-item-content>
              {{ item }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-else>
      <v-chip
        class="mr-1 rounded-0 text-caption"
        v-for="(item, index) in tags"
        :key="index"
      >
        {{ item }}
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  name: "TagGroup",

  props: {
    tags: {
      type: Array,
      default: () => []
    },

    maxShowItems: {
      type: Number,
      default: 3,
    }
  },

  computed: {
    moreItemCount() {
      if (this.tags.length > this.maxShowItems) {
        return this.tags.length - this.maxShowItems + 1
      }

      return 0
    },

    additionalTags() {
      if (this.tags.length > this.maxShowItems) {
        return this.tags.filter((item, index) => index >= this.maxShowItems - 1)
      }

      return []
    }
  },
}
</script>

<style lang="scss">
  .tag-group {
    .more-tag-button {
      min-width: unset !important;
      height: 32px !important;
    }
  }
</style>